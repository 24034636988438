@import '../../scss/_overrides';

.footer{
  height: 275px;
  background-image: url("../../images/wavesFooter.svg");
  background-repeat: no-repeat;
  background-size: cover;

  color: $white;

  a {
    color: $white;
    font-weight: 400;
    font-family: $headings-font-family;

    &:hover {
      border-bottom: 1px solid white;
    }
  }

  .fira-sans {
    font-family: "FiraSans Regular";
  }
}

