@import '../../scss/_overrides';

.navbar {
  background: $midnight;
  min-height: 145px;
  opacity: 1;

  a {
    @media screen and(max-width: $mobile-breakpoint) {
      margin-left: 0.5rem;
      width: fit-content;
    }

    color: $white;
    font-weight: normal;
    padding: 0.5rem 0rem;
    margin-left: 4rem;
    border-bottom: 3px solid transparent;
    font-size: 1.125rem;
    font-family: $headings-font-family;
    font-weight: inherit;

    &.active{
      font-family: $headings-font-family;
    }
  }

  .logo{
    margin-left: 0;
    border-bottom: none ;
    &:hover{
      border-bottom: none ;
    }
  }

  .logo-img {
    @media screen and(max-width:500px) {
      width: 200px;
    }

    width: 345px;
  }
}