//Colours
// $primary: #004200;
$midnight: #15272F;
$primary: $midnight;
$cobalt-10: #104F99;
$primaryLight:#008542;
$secondary: #7ec351;
$secondaryLight: #dceae4;
$tertiary: #249ab8;
$lightblue-10: #ECF1F7;
$fountain: #54C1AF;
$white:#fff;
$dimension-one: rgba($primaryLight, 5);
$dimension-two: rgba($secondary, 2);
$dimension-three: rgba($tertiary, 2);
$dimension-four: #E5F4EE;
$dimension-five: rgba($primary, 0.8);

$body-bg: $lightblue-10;

//Fonts
$headings-font-family:  'JosefinSans Thin', sans-serif;
$body-font-family: 'FiraSans Regular', sans-serif;
$font-family-sans-serif: 'FiraSans Medium', sans-serif;

$link-decoration: none;

$nav-link-font-size: 1.2rem;
$nav-link-font-weight: bold;

//Card
$card-border-width: 0;

$dark-rgb: green;


$mobile-breakpoint: 990px;

