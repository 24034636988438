@font-face {
  font-family: 'Futura Regular';
  src: url(../fonts/FuturaPT-Book.woff2),
    url(../fonts/FuturaPT-Book.woff);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Futura Medium';
    src: url(../fonts/FuturaPT-Medium.woff2), url(../fonts/FuturaPT-Medium.woff);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  
@font-face {
  font-family: 'Futura Bold';
  src: url(../fonts/FuturaPT-Bold.woff2), url(../fonts/FuturaPT-Bold.woff);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FiraSans Regular';
  src: url(../fonts/FiraSans-Regular.woff2),
    url(../fonts/FiraSans-Regular.woff);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'FiraSans Medium';
  src: url(../fonts/FiraSans-Medium.woff2), url(../fonts//FiraSans-Medium.woff);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FiraSans Bold';
  src: url(../fonts/FiraSans-Bold.woff2), url(../fonts/FiraSans-Bold.woff);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans Thin';
  src: url(../fonts/JosefinSans-VariableFont_wght.woff2);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}