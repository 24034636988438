@import '../../scss/_overrides';

.nbp-header {
  @media screen and(max-width:1500px) {
    font-size: 4rem;
  }

  @media screen and(max-width:1200px) {
    font-size: 3rem;
  }

  font-size: 5rem;
  font-weight: 600;
}