@import '../../scss/_overrides';

$white: red !global;

.navbar {
  $white: red !global;

  padding: 0;
  
  a {
    border-bottom: 3px solid transparent;
    color: $cobalt-10;
    font-family: $headings-font-family;
    font-size: 1.25rem;
    font-weight: inherit;
    margin-left: 0;
    padding: 0.5rem 0rem;

    &.inactive {
      background-image: linear-gradient($cobalt-10 0 0);
    }

    &.active {
      border-bottom: 3px solid $cobalt-10;
    }
  }
}