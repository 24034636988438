@import '../../scss/_overrides';

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.enabled {
  &:hover {
    background-color: darken($color: white, $amount: 20);
  }
}