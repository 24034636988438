// Body Stylings
body {
  font-family: $body-font-family ;
  background-color: $lightblue-10;
  
}

.glossary {
  background: #fff;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $primary;
}

h1, .h1 {
  font-size: 3.1rem;
  font-family: $headings-font-family;
  font-weight: 700;
}

h2, .h2 {
  color: $primary;
  font-size: 2rem;
  font-family: $headings-font-family;
}
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: $headings-font-family;
  
}
h5, .h5, h6, .h6 {
  font-family: $font-family-sans-serif;


}

p {
  font-family: $body-font-family;
  font-size: 1rem;

}

a {
  font-family: $body-font-family;
 
  &:hover,
  &:focus {
    color: darken($primary, 15%);
  }
}

// Crawling underline on hover
.fancy-underline {
  background-image: linear-gradient($white 0 0);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/

  &:hover{
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
  }
}

.navbar-expand{
  @media  screen and(max-width:800px) {
    flex-wrap: wrap;
  }
}

.breadcrumbs {
  @media screen and(max-width: $mobile-breakpoint) {
    display: none !important;
  }

  position: absolute;
  top: 120px;
  width: 100%;
}

.breadcrumb {
  background-color: $primary;
  font-family: $font-family-sans-serif;
  margin-bottom:0px;
  padding: 0 10px 10px;

  a.breadcrumb-item{
    color: $white;

    &::before{
        color: $white;      
    }
  
    &:hover{
      text-decoration: underline;
    }
  
}

.breadcrumb-item.active, .breadcrumb-item + .breadcrumb-item::before{
  color: $white; 
  font-family: $font-family-sans-serif;
}

.breadcrumb-item+.breadcrumb-item::before {
  content:var(--bs-breadcrumb-divider,">") !important ;
  padding-top: 1px !important;
  padding-left: 4px !important;
  padding-right: 10px !important;
}
}
// Button Stylings
.MuiButton-root{
  background: $body-bg;
  .MuiButton-label,
  .MuiGrid-container{
    background: $body-bg;
  }
}
.MuiButton-root:hover{
  background: #f9f9f9;
  .MuiButton-label,
  .MuiGrid-container{
    background:#f9f9f9;
  }
}

// Buttons
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  border-radius: 7px;
  font-family: $body-font-family!important;

  .filter-image {
    height: 20px;
    margin: 0% 10%;
  }

  &.link {
    text-decoration: none;
  }
}

.primary-btn {
  padding: 10px;
  background-color: $primaryLight;
  color: $white;
  font-size: 1.162rem;
  max-width: 205px;
  vertical-align: middle;
  align-items: center;
  font-family:$headings-font-family!important;

  span{
    margin-left: 5px;
  }
  &:hover{
    color: $white;
    background-color: $primary;
  }
}

.btn-primary {
  align-items: center;
  background-color: $fountain;
  transition: background-color 0.3s ease;
  border-radius: 0%;
  border: none;
  color: $midnight;
  font-family:$headings-font-family!important;
  font-size: 1.162rem;
  max-width: 205px;
  padding: 10px;
  vertical-align: middle;

  span{
    margin-left: 5px;
  }
  &:hover{
    background-color: darken($color: $fountain, $amount: 10);
  }
}

// Container Styling
.wrapper{
  min-height: calc(100vh - 145px - 275px);
  padding-top: 30px;
}
.container-fluid {
 
  .alert-bar{
  
  display: flex;
  align-items: center;
  width: 100%;
  .card{
    padding: 20px;
    width: 100%;
  background-color: $secondaryLight;
  border-radius: 7px;
  padding: 10px;
  font-family: $body-font-family;
  color: $primary;
  }
}
  .home-container {
    @media screen and(max-width: $mobile-breakpoint) {
      margin-top: 10px;
    }

    margin-top: 220px;
    padding: 0 20px 70px;
    display: flex; align-items: center;
  }
}

// Footer Stylings
.app {
  min-height: calc(100vh - 175px);

}

.site-footer {
  background-color: $white;
  padding: 15px 0;
  width: 100%;
  margin: 2% 0 0 0;
  height: 150px;
}

// Card
.card {
  position: relative;
  border-radius: 0px;
  background: $white;
  margin: 10px 0px;

  .card-body{

    padding: 1.5rem;

    .card-title.h5{
      font-size: 1.8rem;
      font-family: $headings-font-family;
    }
    button.btn.btn-link{
      padding: 0;
    }
  }

  .title-wrap {
    position: relative;
    padding: 0px 0px;

    position: relative;

    .tool-tip {
      background-image: url("../images/tooltip.svg");
      position: absolute;
      height: 20px;
      width: 20px;
      top: 10px;
      right: 0px;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

 

  &.card-primary {
    background-color: $dimension-one;
    color: white;
    border-radius:10px;
  }
  &.card-secondary {
    background-color: $dimension-two;
    color: white;
    border-radius:10px;
  }
  &.card-tertiary {
    background-color: $dimension-three;
    color: white;
    border-radius:10px;
  }
  a{
    font-family: $headings-font-family;
    text-align: right;
    font-size: 1rem;
    color: $primary;
    &:hover{
      text-decoration: underline;
    }
  }
}
.object-wrap {
  overflow: visible !important;

  button{
    float: right;
    background: transparent;
    font-family: $headings-font-family!important;
    color: $primary;
    border: 0;
    padding: 10px;

  }
  button.lui-button{
    padding: 0;
  }
 }

// Hero Stylings
.hero {
  margin: 15vh 100px 0 0;
  color: #004200;
  

}

.qv-object .qv-object-content,
.ng-binding .ng-scope,
div[minor-range-brush],
svg[primary-mes-ref-line-labe],
g,
canvas,
canvas[minor-primary-axis] {
  font-family: $font-family-sans-serif!important;
}
// Banner Stylings
.banner {
  display: flex;
  align-items: center;
  background-color: $lightblue-10;
  padding-top: .8rem;
  width: 100%;
  
  .banner-text{
    width: 100%;
  h1 {
    font-size: 1.6rem;
    color: $primary;
    font-family: $headings-font-family;
    margin-bottom: 0;
  }
}
}

// Style for temporary banner added Dec-24 to announce 2021-22 data 
.new-data-banner {
  text-align: center;
  padding: 5px;
  font-size: 1rem;
  font-family: "FiraSans Regular", sans-serif;
  background-color: $fountain;
  color: $midnight;
}

// Filters
.filters-panel {
  padding: 0;
  display: flex;
  gap: 10px;
  background: $body-bg;

  > div {
    width: 100%;
  }
}
//qlick content 
.sm-btn {
  width: 95% ;
}
.content{
  background-color: white;
  padding: 20px 0;
  min-height: Calc(100vh - 230px);

  .border-r{
    @media  screen and(min-width:800px) {
      border-right: 2px solid $body-bg;
    }
  }
  .border-b  {
    border-bottom: 2px solid $body-bg;
 

  }  
  .border-t article {
    border-top: 2px solid $body-bg;
 

  }  
  .margin{
    margin: 15px 0px;
  }
  .margin-top{
    margin-top: 20px;
  }

  a, button {
    color: $cobalt-10;
    font-weight: 700;
  }

}
// Side-bar
.modal-wrapper {
  position: fixed;
  padding: 30px;
  top: 0;
  left: 0%;
  background-color: $white;
  height: 100%;
  z-index: 1000;
  width: 340px;
  h3 {
    margin-bottom: 30px;
  }
}

.overlay-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 900;
}

.nav-close {
  background: none;
  border: 0;
  position: absolute;
  top: 32px;
  right: 22px;
}
.row1 .col-md-3{
  padding-right: 0;
}

// QLIK OVERRIDES
.qv-object-text-image .qv-media-tool-editor [size="4"], .qv-object-text-image .qv-media-tool-html [size="4"]{
  font-size: 1rem;
  font-family:$font-family-sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiButtonGroup-root {
  background-color: $primary;
}
.qv-object .qv-object-title .qv-object-title-text,
.sn-kpi-value{
  font-family: $body-font-family!important;
  color: #454545;
}


.qv-object-text-image .qv-media-tool-html div {
  margin-bottom: 20px;

}


.qv-object-qlik-variable-input{
  .lui-button.lui-active, .lui-button:active{
    background-color: $primary;
    color: $white;
  }
   div.qlik{
  button{
    color: $primary;
    border: 1px solid $primary;
  }
}}

.cost-p-nwau{
  margin-top: 100px;
}

.col-md-5 .qv-object-content-container .qv-object-content.ng-isolate-scope div{
  font-family: $font-family-sans-serif;
  text-align: right!important;
  div:first-child{
  color: #166E82;
  }
  div:nth-child(2), div:nth-child(2) font{
    color: #5E217D;
    }
    div:nth-child(3), div:nth-child(3) font{
      color: $tertiary;
      }
     
}

//KPI styling override
.sn-kpi .sn-kpi-data > div{
  flex-direction: column!important;
  align-items: flex-start;
}
.sn-kpi-ellips-text .sn-kpi-ellips-text span{
  font-size: 35px;
  padding-right: 5px;
}
.sn-kpi-secondary-wrapper.sn-kpi-ellips-text span{
  font-size: 16px;
}
.sn-kpi .sn-kpi-center{
  justify-content: left!important;
}
.sn-kpi .sn-kpi-data{
  justify-content: end!important;
}
.sn-kpi .sn-kpi-glyph-wrapper i.value-icon{
font-size: .9rem;
}
.sn-kpi .sn-kpi-ellips-text{
  display: flex;
  align-items: center;
  font-size: 14px!important;
  overflow: visible;
}

.qv-object .qv-object-title{
  margin-bottom: 5px;
}
.sn-kpi .sn-kpi-value-wrapper .sn-kpi-no-shrink{
  max-width: 100%!important;
}

//Barchart legend
.cost, .PY{
  font-family: $font-family-sans-serif;
  margin-right: 20px;
  display: flex;
    justify-content: center;
    align-items: center;
}
p.cost span{
 margin-right: 5px;
  background: $secondary;
  width: 10px;
  height: 10px;
  
}
p.PY span{
  margin-right: 5px;
  border-radius: 10px;
   background: $primary;
   width: 10px;
   height: 10px;
   
 }

 //PI menu
 .PImenu{
  .card{
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    min-height: 170px;

    transition: background-color 0.2s;

    a:hover{
      text-decoration: none;
     color: $primary;
    }
  .card-body{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 15px;
    .card-img{
      width: auto;
      align-self: center;
    }
    .card-title.h5{
      align-self: center;
      font-size: 1.5rem;
      flex-grow: 2;
      text-align: left;
      color: $cobalt-10;
      margin-left: 20px;
    }
  }
  
    p{
      text-align: left;
    }
  }

  
}

.accordion{
  .btn{
    background-color: transparent;
    width: 100%;
    border: 0;
    border-bottom: 1px solid $white;
    padding: 10px 0;
    border-radius: 0;
    text-align: left;
    justify-content: left;
    font-size: 1.5rem;
    padding: 0;
  }
  .accordion-body{
    padding:10px 0;
    font-family: $body-font-family;
    label{
    width: 100%;
  }
}
}

.bold {
  font-weight: 700 !important;
}

.bg-dark {
  background-color: $midnight !important;
}

.mobile-border-b {
  @media  screen and(max-width:800px) {
    border-bottom: 2px solid $body-bg;
  }
}

.qv-object .qv-object-title .qv-object-title-text {
  overflow: visible !important;
  white-space: normal !important;
  padding-right: 40px !important;
}